import React from "react";
import SEO from "components/seo";
import Helmet from "react-helmet";

const Careers = () => {
  return (
    <>
      <SEO title="Thomas For Industry Careers" />
      <section className="page-utility" style={{ backgroundColor: "white" }}>
        <div className="container">
          <nav aria-label="breadcrumb" style={{ backgroundColor: "white" }}>
            <ol
              className="breadcrumb"
              style={{ marginBlockStart: "0", marginBlockEnd: "0" }}
            >
              <li className="breadcrumb-item">
                <a href="https://business.thomasnet.com/about/">About</a>
              </li>
              <li className="breadcrumb-item">
                <span xmlns="http://rdf.data-vocabulary.org/#" />
                <span className="breadcrumb_last">Careers</span>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <header className="page-header" style={{ backgroundColor: "white" }}>
        <div className="container" style={{ backgroundColor: "white" }}>
          <h1 className="page-header__title font-weight-normal" style={{ fontSize: "50px" }}>Careers</h1>
        </div>
      </header>
      <article className="section--bg-white">
        <div className="container">
          <div className="hire-jobs" />
          <Helmet>
            <script
              id="hire-embed-loader"
              async
              defer
              src="https://hire.withgoogle.com/s/embed/hire-jobs.js?company=thomaspublishingcom"
            />
          </Helmet>
        </div>
      </article>

      <section className="bg--white">
        <div className="container pt-0">
          <p className="small">
            Thomas is an equal opportunity employer. We believe in providing
            equal employment opportunities to all employees and applicants where
            employment is based upon personal capabilities and qualifications
            without discrimination or harassment because of race, color,
            religion, sex, sexual orientation/affectional preference, gender
            identity, age, national origin, marital status, alienage or
            citizenship status, disability, military or uniformed status,
            genetic predisposition or carrier status or any other protected
            characteristic as established by law.
          </p>
        </div>
      </section>

      <section
        className="content-block content-block--reverse content-block--fullwidth section--bg-dark content-block--one-third "
        data-thdoc="S12"
      >
        <div className="container container--sm">
          <header className="section-header">
            <h2 className="section-header__primary">
              Working at <span>Thomas </span>
            </h2>
          </header>
          <figure className="content-block__media text-lg-center">
            <img
              src="https://careers.thomasnet.com/assets/people-brittany.jpg"
              alt=""
              className="rounded-circle"
            />
          </figure>

          <div className="content-block__body">
            <p className="lead">
              “I also like how there isn’t (just) one career path for you –
              there are opportunities for people to grow within their department
              and outside of their department.”{" "}
            </p>
            <p className="lead">
              <a
                href="http://careers.thomasnet.com/our-people/"
                className="raquo cta-link cta-link-large"
              >
                Find out more about Brittany &amp; others at Thomas »
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
